<template>
  <div
    class="
      navbar-container
      d-flex d-md-none
      content
      align-items-center
      bg-primary
    "
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-md-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- 
        <search-bar />
        <cart-dropdown />
        <notification-dropdown /> 
      -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
// import Bookmarks from './components/Bookmarks.vue'
// import SearchBar from './components/SearchBar.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    // Bookmarks,
    // Locale,
    // DarkToggler,
    // SearchBar,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    isNavMenuHidden: false,
    navbarMenuTypeClass: [],
  },
};
</script>
