<template>
  <div
    class="navbar-container main-menu-content"
    :class="environment == 'test' ? 'bg-warning' : ''"
  >
    <!-- Menu Gauche -->
    <horizontal-nav-menu-items :items="filteredMenuItems" />
    <!-- Logo central -->
    <app-navbar-horizontal-layout-brand />
    <!-- Compte Droite -->
    <b-navbar-nav
      class="nav align-items-center ml-auto"
      style="margin-right: 42px"
    >
      <user-dropdown />
      <horizontal-nav-menu-items :items="navMenuItemsRight" class="left" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import store from "@/store";
import { filteredRightMenuItems } from "@/auth/menu";
import MenuComponent from "@/auth/menu";
import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";
import AppNavbarHorizontalLayoutBrand from "@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue";

import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import UserDropdown from "@/@core/layouts/components/app-navbar/components/UserDropdown.vue";

import { mapGetters, mapActions } from "vuex";
import jwt from "@/auth/jwt/useJwt";
import { menuLeft } from "@/data/menu";

export default {
  components: {
    BLink,
    BNavbarNav,

    HorizontalNavMenuItems,
    AppNavbarHorizontalLayoutBrand,

    Locale,
    UserDropdown,
  },
  extends: MenuComponent,
  setup() {
    return {};
  },
  data() {
    return {
      environment: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "institution",
      "institutionsList",
      "userCollaboratorId",
      "userParametersList",
    ]),
    // navMenuItems() {
    //   return filteredMenuItems;
    // },
    navMenuItemsRight() {
      return filteredRightMenuItems;
    },
    userRolesLocal() {
      let info = JSON.parse(localStorage.getItem("userInfo"));
      return info.roles ? info.roles : [];
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
