<template>
  <div class="navbar-header d-md-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" :to="homePage">
          <span class="brand-logo">
            <!-- <b-img
              :src="appLogoFull"
              alt="logo"
            /> -->
            <b-img
              :src="
                workspaceSelected && workspaceSelected.logo
                  ? workspaceSelected.logo
                  : appLogoFull
              "
              alt="logo"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BLink,
    BImg,
  },
  computed: {
    ...mapGetters(["workspaceSelected", "userParametersList"]),
    homePage() {
      if (this.userParametersList.find((el) => el.key == "homePage")) {
        return this.userParametersList.find((el) => el.key == "homePage").value;
      } else {
        return "/";
      }
    },
  },
  setup() {
    // App Name
    const { appName, appLogoFull } = $themeConfig.app;
    return {
      appName,
      appLogoFull,
    };
  },
};
</script>
